define("discourse/plugins/discourse-media-wiki-import/controllers/admin-plugins-media-wiki-import", ["exports", "I18n", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/controller"], function (_exports, _I18n, _ajax, _ajaxError, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    importing: false,
    init() {
      this._super(...arguments);
      this.importRunning();
    },
    importRunning(isImporting) {
      (0, _ajax.ajax)("/media-wiki-import/import-running", {
        type: "GET"
      }).then(result => {
        this.set("importing", result);
        if (result) {
          // check again in 10 seconds
          setTimeout(() => this.importRunning(), 10000);
        } else if (isImporting) {
          // import completed
          this.set("successMessage", "Import finished");
        }
        isImporting = result;
      }).catch(_ajaxError.popupAjaxError);
    },
    actions: {
      uploadComplete() {},
      import() {
        this.setProperties({
          errorMessageExportFile: null,
          errorMessageCategory: null,
          successMessage: null
        });
        if (!this.uploadedMediaWikiExportUrl) {
          this.setProperties({
            errorMessageExportFile: _I18n.default.t("media_wiki_import.import.error_missing_export_file")
          });
          return;
        }
        if (!this.categoryId) {
          this.setProperties({
            errorMessageCategory: _I18n.default.t("media_wiki_import.import.error_missing_category")
          });
          return;
        }
        this.set("importing", true);
        (0, _ajax.ajax)("/media-wiki-import/import", {
          type: "POST",
          data: {
            uploadedMediaWikiExportUrl: this.uploadedMediaWikiExportUrl,
            categoryId: this.categoryId
          }
        }).then(result => {
          this.setProperties({
            successMessage: result
          });

          // check if import is still running
          setTimeout(() => this.importRunning(), 5000);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });
});