define("discourse/plugins/discourse-media-wiki-import/discourse/components/media-wiki-uploader", ["exports", "discourse-common/utils/decorators", "@ember/component", "discourse/mixins/uppy-upload"], function (_exports, _decorators, _component, _uppyUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_uppyUpload.default, dt7948.p({
    type: "mediawiki-export",
    tagName: "span",
    validateUploadedFilesOptions() {
      return {
        skipValidation: true
      };
    },
    uploadDone(upload) {
      this.setProperties({
        uploadedMediaWikiExportUrl: upload.url,
        uploadedMediaWikiExportId: upload.id,
        uploadedMediaWikiExportDisplayName: `${upload.original_filename} (${upload.human_filesize})`
      });
      this.done();
    },
    data(user_id) {
      return {
        user_id
      };
    }
  }, [["method", "data", [(0, _decorators.default)("user_id")]]]));
});